import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { DomSanitizer} from '@angular/platform-browser';

import { FlexLayoutModule, MediaMarshaller } from '@angular/flex-layout';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OrganizationComponent } from './pages/organization/organization.component';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { CountryComponent } from './pages/country/country.component';
import { AllCountriesComponent } from './components/all-countries/all-countries.component';
import { LoginComponent, ForgotPasswordDialog } from './pages/login/login.component';
import { AuthService, AuthInterceptor } from './services/auth/auth.service';
import { LogoutComponent } from './pages/logout/logout.component';
import { MainComponent } from './main/main.component';
import { HttpErrorInterceptor } from './util/http-error.interceptor';
import { CountryListItemComponent } from './components/country-list-item/country-list-item.component';
import { CountryListComponent } from './components/country-list/country-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventCalendarComponent } from './components/event-calendar/event-calendar.component';
import { CountryProfileComponent } from './pages/country-profile/country-profile.component';
import { CountrySnapshotComponent } from './pages/country-snapshot/country-snapshot.component';
import { CompareComponent } from './pages/compare/compare.component';
import { ContactUsComponent, ContactUsDialog } from './components/contact-us/contact-us.component';
import { RequestCountryComponent, RequestCountryDialog } from './components/request-country/request-country.component';
import { CountryUpdatesComponent } from './components/country-updates/country-updates.component';
import { UpdatesComponent } from './pages/updates/updates.component';
import { ComplianceSummaryComponent } from './components/compliance-summary/compliance-summary.component';
import { CountryUpdatesAsListComponent } from './components/country-updates-as-list/country-updates-as-list.component';
import { CountrySettingsComponent, EditSettingsDialog } from './components/country-settings/country-settings.component';
import { UpdateListComponent } from './components/update-list/update-list.component';
import { CountryProfileSectionsComponent } from './components/country-profile-sections/country-profile-sections.component';
import { CountryProfileSectionComponent } from './components/country-profile-section/country-profile-section.component';
import { CountryProfileTreeComponent } from './components/country-profile-tree/country-profile-tree.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AlertDialog } from './util/alert-dialog/alert-dialog';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { OrganizationCountryListComponent } from './components/organization-country-list/organization-country-list.component';
import { OrganizationRequestCountryComponent } from './components/organization-request-country/organization-request-country.component';
import { OrganizationMemberListComponent } from './components/organization-member-list/organization-member-list.component';
import { AuthGuard } from './services/auth/auth-guard.service';
import { OwnerRoleGuard } from './services/auth/owner-role-guard.service';
import { OrganizationEditMemberComponent, OrganizationEditMemberDialog, DuplicateEmailValidatorDirective } from './components/organization-edit-member/organization-edit-member.component';
import { OrganizationAddMemberComponent } from './components/organization-add-member/organization-add-member.component';
import { CountrySnapshotSectionsComponent } from './components/country-snapshot-sections/country-snapshot-sections.component';
import { CountrySnapshotSectionComponent } from './components/country-snapshot-section/country-snapshot-section.component';
import { CountryDatasetComponent } from './components/country-dataset/country-dataset.component';
import { CountryBannerComponent } from './components/country-banner/country-banner.component';
import { CountryNewsComponent } from './components/country-news/country-news.component';
import { CountryNewsItemComponent } from './components/country-news-item/country-news-item.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { CompareTableComponent } from './components/compare-table/compare-table.component';
import { CountryDocumentsComponent } from './components/country-documents/country-documents.component';
import { RedirectGuard } from './util/redirect-guard.service';
import { CountriesComponent } from './pages/countries/countries.component';
import { ErrorComponent } from './pages/error/error.component';
import { CountryEventCalendarComponent } from './components/country-event-calendar/country-event-calendar.component';
import { CountryProfileDownloadComponent } from './components/country-profile-download/country-profile-download.component';
import { HolidaysMatrixComponent } from './pages/holidays-matrix/holidays-matrix.component';
import { AdminRoleGuard } from './services/auth/admin-role-guard.service';
import { RollbarErrorHandler, RollbarService, rollbarFactory } from './util/rollbar';
import { PreferencesComponent } from './pages/preferences/preferences.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TokenLoginComponent } from './pages/token-login/token-login.component';
import { CurrencyConverterComponent } from './components/currency-converter/currency-converter.component';
import { CountryTimeComponent } from './components/country-time/country-time.component';
import { AddDocumentComponent, AddDocumentDialog } from './components/add-document/add-document.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { CheckoutCompleteComponent } from './pages/checkout-complete/checkout-complete.component';
import { CountryProfileSearchComponent, SearchResultsDialog } from './components/country-profile-search/country-profile-search.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { CalendarMonthHeaderComponent } from './components/calendar-month-header/calendar-month-header.component';
import { CalendarListComponent } from './components/calendar-list/calendar-list.component';
import { CalendarListDayComponent } from './components/calendar-list-day/calendar-list-day.component';
import { CalendarListCountryComponent } from './components/calendar-list-country/calendar-list-country.component';
import { CalendarGridComponent } from './components/calendar-grid/calendar-grid.component';
import { CalendarGridDayComponent } from './components/calendar-grid-day/calendar-grid-day.component';
import { CalendarGridEventComponent } from './components/calendar-grid-event/calendar-grid-event.component';
import { CalendarDayPopupComponent } from './components/calendar-day-popup/calendar-day-popup.component';
import { CalendarFilterComponent } from './components/calendar-filter/calendar-filter.component';
import { CalendarFilterPopupComponent } from './components/calendar-filter-popup/calendar-filter-popup.component';
import { CalendarPrintComponent } from './components/calendar-print/calendar-print.component';
import { CalendarPrintPopupComponent } from './components/calendar-print-popup/calendar-print-popup.component';
import { MatNativeDateModule } from '@angular/material/core';
import { CalendarPrintListComponent } from './components/calendar-print-list/calendar-print-list.component';
import { CalendarPrintListDayComponent } from './components/calendar-print-list-day/calendar-print-list-day.component';
import { CalendarPrintListCountryComponent } from './components/calendar-print-list-country/calendar-print-list-country.component';
import { CalendarExportComponent } from './components/calendar-export/calendar-export.component';
import { CalendarExportPopupComponent } from './components/calendar-export-popup/calendar-export-popup.component';
import { CalendarEventFormComponent } from './components/calendar-event-form/calendar-event-form.component';
import { CalendarAddEventComponent } from './components/calendar-add-event/calendar-add-event.component';
import { PromotionSidebarComponent } from './components/promotion-sidebar/promotion-sidebar.component';
import { PromotionPageComponent } from './components/promotion-page/promotion-page.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EmployeeCalculatorComponent } from './pages/employee-calculator/employee-calculator.component';
import { EmploymentCostsCalculatorComponent } from './components/employment-costs-calculator/employment-costs-calculator.component';
import { DecimalPipe } from '@angular/common';

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        OrganizationComponent,
        CountryComponent,
        AllCountriesComponent,
        LoginComponent,
        ForgotPasswordDialog,
        LogoutComponent,
        MainComponent,
        CountryListItemComponent,
        CountryListComponent,
        EventCalendarComponent,
        CountryProfileComponent,
        CountrySnapshotComponent,
        CompareComponent,
        ContactUsComponent,
        ContactUsDialog,
        RequestCountryComponent,
        RequestCountryDialog,
        CountryUpdatesComponent,
        UpdatesComponent,
        ComplianceSummaryComponent,
        CountryUpdatesAsListComponent,
        CountrySettingsComponent,
        EditSettingsDialog,
        UpdateListComponent,
        CountryProfileSectionsComponent,
        CountryProfileSectionComponent,
        CountryProfileTreeComponent,
        ResetPasswordComponent,
        AlertDialog,
        ChangePasswordComponent,
        OrganizationCountryListComponent,
        OrganizationRequestCountryComponent,
        OrganizationMemberListComponent,
        OrganizationEditMemberComponent,
        OrganizationAddMemberComponent,
        OrganizationEditMemberDialog,
        DuplicateEmailValidatorDirective,
        OrganizationAddMemberComponent,
        CountrySnapshotSectionsComponent,
        CountrySnapshotSectionComponent,
        CountryDatasetComponent,
        CountryBannerComponent,
        CountryNewsComponent,
        CountryNewsItemComponent,
        DocumentsComponent,
        DocumentListComponent,
        CompareTableComponent,
        CountryDocumentsComponent,
        CountriesComponent,
        ErrorComponent,
        CountryEventCalendarComponent,
        CountryProfileDownloadComponent,
        HolidaysMatrixComponent,
        PreferencesComponent,
        NotFoundComponent,
        TokenLoginComponent,
        CurrencyConverterComponent,
        CountryTimeComponent,
        AddDocumentComponent,
        AddDocumentDialog,
        PurchaseComponent,
        CheckoutCompleteComponent,
        CountryProfileSearchComponent,
        SearchResultsDialog,
        CalendarComponent,
        CalendarMonthHeaderComponent,
        CalendarListComponent,
        CalendarListDayComponent,
        CalendarListCountryComponent,
        CalendarGridComponent,
        CalendarGridDayComponent,
        CalendarGridEventComponent,
        CalendarDayPopupComponent,
        CalendarFilterComponent,
        CalendarFilterPopupComponent,
        CalendarPrintComponent,
        CalendarPrintPopupComponent,
        CalendarPrintListComponent,
        CalendarPrintListDayComponent,
        CalendarPrintListCountryComponent,
        CalendarExportComponent,
        CalendarExportPopupComponent,
        CalendarEventFormComponent,
        CalendarAddEventComponent,
        PromotionSidebarComponent,
        PromotionPageComponent,
        SetPasswordComponent,
        EmployeeCalculatorComponent,
        EmploymentCostsCalculatorComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        MatGridListModule,
        MatToolbarModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatListModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTableModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatBadgeModule,
        FormsModule,
        ReactiveFormsModule,
        MatTreeModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatRadioModule,
        CKEditorModule,
        HttpClientJsonpModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatPaginatorModule,
    ],
    providers: [
        AuthService,
        AuthGuard,
        OwnerRoleGuard,
        AdminRoleGuard,
        RedirectGuard,
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        DecimalPipe

    ],
    bootstrap: [AppComponent]
})

export class AppModule {

  // This is all a hack until this issue is resolved: https://github.com/angular/flex-layout/issues/1201
  lastValue;

  public constructor(
    m: MediaMarshaller,
  ) {
    // @ts-ignore
    m.subject.subscribe((x) => {
      // @ts-ignore
      if (m.activatedBreakpoints.filter((b) => b.alias === 'print').length === 0) {
        // @ts-ignore
        this.lastValue = [...m.activatedBreakpoints];
      } else {
        // @ts-ignore
        m.activatedBreakpoints = [...this.lastValue];
        // @ts-ignore
        m.hook.collectActivations = () => {};
        // @ts-ignore
        m.hook.deactivations = [...this.lastValue];
      }
    });
  }
 }
