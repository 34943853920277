<div class="main__toolbar-wrapper" ngClass.lt-md="medium-breakpoint">
  <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="main__toolbar">
    <a [routerLink]="['/dashboard']" class="main__logo-link" fxHide.xs="true"><img src="/assets/images/logo-header.png"/></a>

    <div fxLayout="row" class="main__menu-wrapper">
      <a mat-menu-item [routerLink]="['/dashboard']"><mat-icon svgIcon="globe"></mat-icon><span>Countries</span></a>
      <a mat-menu-item [routerLink]="['/documents']"><mat-icon svgIcon="document"></mat-icon><span>Documents</span></a>
      <a mat-menu-item [routerLink]="['/calendar']"><mat-icon svgIcon="calendar"></mat-icon><span>Calendar</span></a>
      <a mat-menu-item [routerLink]="['/updates']"><mat-icon svgIcon="bell"></mat-icon><span>Updates</span></a>
      <a mat-menu-item *ngIf="isOwner()|async" [routerLink]="['/organization']" fxShow.xs="false"><mat-icon svgIcon="gear"></mat-icon><span>Setup</span></a>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" class="main__menu-welcome-wrapper" ngClass.lt-md="medium-breakpoint">
      <a mat-menu-item [matMenuTriggerFor]="appMenu" >Welcome<span  *ngIf="currentUser$ | async as user">, {{ user?.first_name }}</span><mat-icon svgIcon="down"></mat-icon></a>
    </div>
  </mat-toolbar>
</div>
<!-- Moved outside the mat-toolbar so it doesn't mess up the spacing -->
<mat-menu #appMenu="matMenu">
  <a mat-menu-item [routerLink]="['/preferences']">Update Preferences</a>
  <a *ngIf="hasSetPassword()|async" mat-menu-item [routerLink]="['/changepassword']">Change Password</a>
  <a *ngIf="!(hasSetPassword()|async)" mat-menu-item [routerLink]="['/setpassword']">Set Password</a>
  <a *ngIf="isAdmin()|async" mat-menu-item [routerLink]="['/admin']">Admin Site</a>
  <a *ngIf="isAdmin()|async" mat-menu-item [routerLink]="['/holidays']">Holiday Matrix</a>
  <a mat-menu-item [routerLink]="['/logout']">Logout</a>
</mat-menu>

<div class="content">
  <router-outlet></router-outlet>
</div>

<mat-toolbar fxLayout="row" fxLayoutAlign="left" class="main__footer">
  <a href="https://www.globalpeoplestrategist.com/terms-of-use/" target="_blank">Terms of Service</a><span class="main__footer-divider">|</span>
  <a href="https://www.globalpeoplestrategist.com/blog/" target="_blank">Blog</a><span class="main__footer-divider">|</span>
  <app-contact-us></app-contact-us>
</mat-toolbar>
