export const environment = {
  production: true,
  environment_name: 'Production',
  api_root: 'https://app.globalpeoplestrategist.com/',
  cache_timeout: 1000 * 60 * 60,   /* Remove all local caches after one hour */
  cors_proxy: "https://gps-cors-proxy-2.herokuapp.com/",
  mixpanel_token: '80ac0145c6a128663abd33a83f7165e1',
  currency_token: 'b0ea79297dab406aadb9734878bf7215',
  chargebee_site: 'globalpeoplestrategist'
};
